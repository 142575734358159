import { LiveConcert, Video, VodConcert } from 'generated/graphql';
import { LiveConcertBadge } from 'src/components/live-concert/live-concert-badge';
import { NodeFreeBadge } from 'src/components/node/node-free-badge';

type NodeTeaserBadgeProps = {
  node:
    | Pick<Video | VodConcert, '__typename' | 'publicationLevel' | 'typeDisplayName'>
    | Pick<
        LiveConcert,
        '__typename' | 'publicationLevel' | 'typeDisplayName' | 'startTime' | 'endTime' | 'streamStartTime' | 'reruns'
      >;
};

/**
 * Renders one ore more badges for a given node (video, vod concert, live concert).
 * It can either be a free badge, or a free badge and a live badge.
 */
export function NodeTeaserBadge({ node }: NodeTeaserBadgeProps) {
  const isLiveConcert = node.__typename === 'LiveConcert';

  return (
    <div className="flex space-x-2" data-test="node-badge">
      <NodeFreeBadge node={node} />
      {isLiveConcert ? <LiveConcertBadge liveConcert={node} /> : null}
    </div>
  );
}
