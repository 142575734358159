import clsx from 'clsx';
import { useTitleClamp } from 'src/hooks/use-title-clamp';

/** Max number of individual lines that primary or secondary title may have */
const maxTitleLines = 3;
/** Max number of combined lines that primary and secondary title may have */
const maxTitleLinesTotal = 4;

type TeaserTitleProps = {
  primary: string;
  secondary?: string;
  center?: boolean;
};

/**
 * Renders the title for a teaser component. The title consists of a primary and optionally a secondary title.
 * It automatically calculates the optimal number of lines for the primary and secondary title on component mount and will use an ellipsis if the title is too long.
 */
export function TeaserTitle({ primary, secondary, center }: TeaserTitleProps) {
  const { titleRef, titleClamp } = useTitleClamp<HTMLDivElement>(maxTitleLines);
  /** The secondary title takes up the remaining number of lines available */
  const secondaryTitleClamp = maxTitleLinesTotal - titleClamp;

  return (
    <div className="pt-3">
      <div
        className={clsx(
          'dg-text-medium-3',
          titleClamp === 1 && 'line-clamp-1',
          titleClamp === 2 && 'line-clamp-2',
          titleClamp === 3 && 'line-clamp-3',
          center && 'text-center',
        )}
        ref={titleRef}
      >
        {primary}
      </div>
      {secondary && (
        <div
          className={clsx(
            'dg-text-light-3 text-white',
            secondaryTitleClamp === 1 && 'line-clamp-1',
            secondaryTitleClamp === 2 && 'line-clamp-2',
            secondaryTitleClamp === 3 && 'line-clamp-3',
            center && 'text-center',
          )}
        >
          {secondary}
        </div>
      )}
    </div>
  );
}
