type TeaserProgressProps = {
  /**
   * The progress value between 0 and 1
   */
  value: number;
};

/**
 * The progress indicator of the video teaser
 */
export const TeaserProgress = ({ value }: TeaserProgressProps) => {
  return (
    <div className="-mb-3 flex h-3 items-center" data-test="teaser-progress" data-value={value}>
      <div className="relative h-0.5 w-full bg-divider/10">
        <div
          className="absolute left-0 top-0 w-full origin-top-left border-t-2 border-brandYellowC1"
          style={{ transform: `scale3d(${value}, 1, 1)` }}
        ></div>
      </div>
    </div>
  );
};
