import useTranslate from 'src/hooks/use-translate';
import { secondsToMinutes } from 'src/utilities/date-helpers';

type MediaDurationProps = {
  duration: number;
};

/**
 * Renders the duration of a media item in minutes.
 * Used by other components like video teasers or audio detail pages.
 *
 * @example
 * ```tsx
 * <MediaDuration duration={1234} /> // 21 Min
 * ```
 */
export default function MediaDuration({ duration }: MediaDurationProps) {
  const t = useTranslate();

  return (
    <span className="lining-nums" data-test="media-duration">
      {t('video__duration_in_minutes', [secondsToMinutes(duration)])}
    </span>
  );
}
