import { LiveConcert } from 'generated/graphql';
import Time from 'src/components/time';
import useHasMounted from 'src/hooks/use-has-mounted';
import useLiveConcertState from 'src/hooks/use-live-concert-state';
import useTranslate from 'src/hooks/use-translate';

type LiveConcertTimeProps = {
  liveConcert: Pick<LiveConcert, 'endTime' | 'startTime' | 'streamStartTime' | 'reruns'>;
};

/**
 * Renders the live concert time based on the current time and the live concert start time.
 * When the live concert is in the future, it renders the start time.
 * When the live concert is live, it renders "Live now".
 * When the live concert is in the past, it renders "Available soon in the archive".
 */
export function LiveConcertTime({ liveConcert }: LiveConcertTimeProps) {
  const t = useTranslate();
  const hasMounted = useHasMounted();
  const { status, startTime } = useLiveConcertState(liveConcert);

  // Only render this component on the client because the server can have a different time zone than the client
  if (!hasMounted) return null;

  if (status === 'past') return <span>{t('live_concert__status_label_past')}</span>;

  if (!startTime) return null;

  if (status === 'live' || status === 'pre-live') {
    return <span className="uppercase text-brandYellowC1">{t('live_concert__live_now')}</span>;
  }

  return <Time value={startTime} style="shortTime" />;
}
