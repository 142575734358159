import { HTMLProps, PropsWithChildren } from 'react';

type TeaserMetaProps = Omit<PropsWithChildren<HTMLProps<HTMLDivElement>>, 'className'>;

/**
 * Displays meta information about a teaser (e.g. duration, date, etc.)
 */
export function TeaserMeta(props: TeaserMetaProps) {
  return (
    <div
      className="dg-text-regular-4 flex flex-row items-center space-x-2 pt-1.5 text-greyG2 md:pt-2 lg:pt-3"
      {...props}
    />
  );
}
