import { useEffect, useRef, useState } from 'react';

export function useTitleClamp<T extends HTMLElement>(maxLines: number) {
  const titleRef = useRef<T>(null);
  const [titleClamp, setTitleClamp] = useState(1);

  useEffect(() => {
    // Don't do anything if the ref is not ready yet
    if (!titleRef.current) return;

    /**
     * Calculate the number of lines of the title. By default, the title is clamped to 1 line.
     * This allows us to check the actual height of the title element using `scrollHeight`.
     * The `offsetHeight` is the visible height of the element.
     * By dividing them we get the number of lines that the title would have if it was not clamped.
     */
    const titleLines = Math.round(titleRef.current.scrollHeight / titleRef.current.offsetHeight);
    setTitleClamp(Math.min(titleLines, maxLines));
  }, [maxLines]);

  return { titleRef, titleClamp };
}
